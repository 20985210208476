import React, { useState, useEffect } from 'react'

//react router imports
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//styling imports
import "../styles/global.css";
import Steve from '../Images/Verify_steve.png'

var loopCounter = 0
function Verify(props) {
    const [userId, setUserId] = useState("na")
    //helper vars to extract id from url
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search); 
    // navigate to next page 
    const navigate = useNavigate();


    // email = props.location.state.email
// END

// Functions
    function redirectToLanding(){
        navigate("/", {});
    }

    function loopForRedirect(){
        console.log("loop for redirect l29 verify.js + id is " + searchParams.get('id'))
        loopCounter = loopCounter + 1 
        fetch('api/isVerifiedUser', {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                    "id": searchParams.get('id')
            }),})
            .then(response => response.json())
            .then(data => {
                if (data.verified == false && loopCounter < 300){
                    console.log("step1. got response but not verified")
                    window.setTimeout(() => {
                        console.log("step 2. Loop counter is" + loopCounter)
                        loopForRedirect()
                    }, 1000);
                } else {
                    console.log("verified")
                    // navigate("/welcome", { id: userId });
                    window.location.href = "https://read.first1000.co";
                }
            })
            .catch((error) => {
                console.error('Error Fetch Verify JS');
                console.log(error)
            })
    }
    useEffect(() => {
        loopForRedirect(userId)
        },[searchParams.get('id')])


  return (
    <div className="Page-div">
      <div className="Mobile_top_padding"></div>
      <div className="Mobile_top_padding"></div>
      <div className="Mobile_top_padding"></div>
      <div className="Desktop_top_neg_padding"></div>
        <p className="Color-red Text-h1 Headline_landing_width Margin_zero"> Alright, just sent you a verification link!</p>
        <div className="Mobile_top_padding"></div>
        <br></br>
        <p className="Text-h3 Color-black"> Check your low-priority/spam inbox. </p>
        <p className="Text-h3 Color-black">Email is from<span className="Bold"> “First 1000” </span> </p>
        <img src={Steve} className="Img_sticky_bottom Img_landing_size"></img>
    </div>
  );
}

export default Verify;