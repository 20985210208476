import React, {useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Verified() {
    var [id, setId] = useState("na")
    var [mvp, setMVP] = useState("ve")
    //MVPs can be 
    // pw_rs -> product_work_resurrection_email
    // pw_rs2 -> product_work_resurrection_email
    // ve -> verification_email
    const navigate = useNavigate()
    //used to parse query strings!!
    function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        // console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
}

    useEffect(() => {
        setId(getQueryVariable('id'))
        setMVP(getQueryVariable('mvp'))
        console.log("mvp is " + mvp)
        console.log("id is " + id)
        if (id != "na"){
            fetch('https://www.first1000.co/api/verifyUser', {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                    "id": id,
                    "mvp":mvp
            }),})
            .then(response => response.json())
            .then(data => {
                console.log("Verified.js l45")
                if (data.success == "true"){
                    // navigate("/welcome", { id: id});
                    window.location.href = "https://read.first1000.co/archive/";
                }
            })
            .catch((error) => {
            console.error('Error Fetch Verified.js');
            })
        }
      },[id]); 

    return (
        <div>
            <h1> Email verified {id} </h1>
        </div>
    );
  }
  
  export default Verified;