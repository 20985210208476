export const http_source = () => {
  if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return ["search",'google']
  } else if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
    return ["search",'bing'];
  } else if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
    return ["search",'yahoo'];
  } else if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
    return ["social_share",'facebook'];
  } else if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
    return ["social_share",'twitter'];
  } else if (document.referrer.search('https?://(.*)t.([^/?]*)') === 0) {
    return ["social_share",'twitter'];
  } else if (document.referrer.search('https?://(.*)duckduckgo.([^/?]*)') === 0) {
    return ["search",'duckduckgo'];
  } else if (document.referrer.search('https?://(.*)slack.([^/?]*)') === 0) {
    return ["social_share","slack"]
  } else if (document.referrer.search('https?://(.*)microsoft.([^/?]*)') === 0) {
    return ["social_share",'teams'];
  } else if (document.referrer.search('https?://(.*)lnkdn.([^/?]*)') === 0) {
    return ["social_share",'linkedin'];
  } else if (document.referrer.search('https?://(.*)telegram.([^/?]*)') === 0) {
    return ["social_share",'telegram'];
  } else if (document.referrer.search('https?://(.*)zoom.([^/?]*)') === 0) {
    return ["social_share",'zoom'];
  } else if (document.referrer.search('https?://(.*)instapaper.([^/?]*)') === 0) {
    return ["social_share",'instapaper'];
  } else if (document.referrer.search('https?://(.*)linkedin.([^/?]*)') === 0) {
    return ["social_share",'linkedin'];
  } else if (document.referrer.search('https?://(.*)workplace.([^/?]*)') === 0) {
    return ["social_share",'workplace'];
  } else if (document.referrer.search('https?://(.*)feedly.([^/?]*)') === 0) {
    return ["social_share",'feedly'];
  } else if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
    return ['search','bing'];
  } else if (document.referrer.search('https?://(.*)instagram.([^/?]*)') === 0) {
    return ["social_share",'Instagram'];
  } else if (document.referrer.search('https?://(.*)producthunt.([^/?]*)') === 0) {
    return ["turbo_boost",'productHunt'];
  } else if (document.referrer.search('https?://(.*)duckduckgo.([^/?]*)') === 0) {
    return ["search",'duckduckgo'];
  } else if (document.referrer.search('https?://(.*)lennysnewsletter.([^/?]*)') ===0) {
    return ["turbo_boost",'lenny']
  } else if (document.referrer.search('https?://(.*)substack.([^/?]*)') ===0) {
    return ["social_share",'substack']
  } else if (document.referrer.search('https?://(.*)Coda.([^/?]*)') ===0) {
    return ["turbo_boost",'coda']
  } else if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') ===0) {
    return ["search",'yahoo']
  } else if (document.referrer.search('https?://(.*)indiehackers.([^/?]*)') ===0) {
    return ["social_share",'indieHackers']
  } else if (document.referrer.search('https?://(.*)youtube.([^/?]*)') ===0) {
    return ["social_share",'youtube']
  } else if (document.referrer.search('https?://(.*)tiktok.([^/?]*)') ===0) {
    return ["social_share",'tiktok']
  } else if (document.referrer.search('https?://(.*)trello.([^/?]*)') ===0) {
    return ["social_share",'trello']
  } else if (document.referrer.search('https?://(.*)yandex.([^/?]*)') ===0) {
    return ["search",'yandex']
  } else if (document.referrer.search('https?://(.*)reddit.([^/?]*)') ===0) {
    return ["social_share",'reddit']
  } else if (document.referrer.search('https?://(.*)medium.([^/?]*)') ===0) {
    return ["social_share",'medium']
  } else if (document.referrer ==='') {
    return ['$direct','$direct']
  } else {
    return ["new_channel",document.referrer]
  }
};
