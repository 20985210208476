// react components
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import posthog from 'posthog-js';
//pages
import Landing from './pages/Landing'
import Verify from './pages/Verify'
import Welcome from './pages/Welcome'
import Verified from './pages/Verified'

posthog.init('phc_QEI4N21dFvykQP7OQ06RBBd47Qfel2VNgdwz9133d0W', { api_host: 'https://app.posthog.com' });

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<Landing/>} />
          <Route path='/verify' element={<Verify/>} />
          <Route path='/welcome' element={<Welcome/>} />
          <Route path='/verified' element={<Verified/>} />
          {/* <Route path="/verify" exact component= {Verify} /> */}
          {/* <Route path="/join" exact component={Join} />
          <Route path="/welcome" exact component={PostAuthV3} />
          <Route path="/refer" exact component={Refer} />
          <Route path="/dashboard" exact component={Earn} />
          <Route path="/signup" exact component={AltSignUp} />
          <Route path="/menadb" exact component={MenaDB} />
          <Route path="/trump" exact component={Trump} />
          <Route path="/trumpa" exact component={TrumpFull} />
          <Route path="/Caculator" exact component={Caculator} />
          <Route path="/interviews" exact component={Interviews} />
          <Route path="/early-stage" exact component={EarlyStage} />
          <Route path="/growth-db" exact component={GrowthDB} />
          <Route path="/unicorn-pd" exact component={UnicornDB} />
          <Route path="/angel" exact component={Angel} /> */
          /* <Route path="/verified" exact component= {Verified} />
          <Route path="/bye-forever" exact component= {Unsubscribed} />
          <Route path="/never" exact component= {Never} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
