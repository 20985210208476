import React, { useEffect, useState } from "react";
import posthog from "posthog-js";
import "./Landing.css";
import "../styles/global.css";
import Submit from "../components/submit/Submit";
import Steve from "../Images/Landing_steve.png";

function Landing() {
  const [featureFlagVariant, setFeatureFlagVariant] = useState(null);
  const [companyNames] = useState(["BeReal", "Onlyfans", "Airbnb", "Tinder", "Spotify", "Stripe"]);
  const [companyIndex, setCompanyIndex] = useState(0);

  useEffect(() => {
    const distinctId = posthog.get_distinct_id();
    console.log(distinctId);
    fetch(`/api/feature-flags/headline-version-c/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ distinct_id: distinctId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Server responded with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.variant);
        setFeatureFlagVariant(data.variant);
      })
      .catch((error) => {
        console.error('Error fetching feature flags:', error);
      });

    const intervalId = setInterval(() => {
      setCompanyIndex(prevIndex => (prevIndex + 1) % companyNames.length);
    }, 4000); // 4 seconds for each name including fade in and fade out

    return () => clearInterval(intervalId);
  }, []);

  const companyName = companyNames[companyIndex];
  const headlineText = "Get your ";
  const customers = "#first1000";
  const restOfHeadline = " customers by copying what worked for";

  return (
    <div className="Page-div">
      <div className="Mobile_top_padding"></div>
      <div className="Mobile_top_padding"></div>
      <div className="Mobile_top_padding"></div>
      <div className="Desktop_top_neg_padding"></div>
      <div className="Headline_container">
        <p className="Color-black Text-h1 Headline_landing_width Margin_zero Text-left">
          {featureFlagVariant === "test" ? (
            <>
              {headlineText}
              <span className="Color-red">{customers}</span>
              {restOfHeadline}
            </>
          ) : (
            <span className="Color-red">How Founders Got Their First 1000 Users</span>
          )}
        </p>
        {featureFlagVariant === "test" && (
          <p className="Color-red Text-h1 Headline_landing_width Margin_zero fade-in-out Headline_company_name">
            {companyName}
          </p>
        )}
      </div>
      <div className="Mobile_top_padding"></div>
      <br></br>
      <Submit></Submit>
      <p className="Text-h6 Color-black">
        100+ newsletters featuring experiments, tactics and stories from{" "}
        <span className="Bold">
          BeReal, Onlyfans, Airbnb, Tinder, Spotify, Tinder, Stripe and more
        </span>{" "}
      </p>
      <img src={Steve} className="Img_sticky_bottom Img_landing_size"></img>
    </div>
  );
}
export default Landing;