import './Submit.css'
import '../../styles/global.css'
import React, { useState } from 'react';
import {http_source} from '../../front_utilities/http_source';
import { useNavigate } from "react-router-dom";

function Submit (props) {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState("N")
    const source = http_source()
    const navigate = useNavigate()

    function changeName(event) {
        event.preventDefault()
        setEmail(event.target.value)
        setError("N")
        // console.log(http_source())
    }

    const handleSignup = async (event) => {
        event.preventDefault();
        setLoading(true);
        // Handle the form submission here (e.g. send the data to the server)
        try {
            const response = await fetch("https://www.first1000.co/api/email", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email.toLowerCase(),
                    source: source[0],
                    channel: source[1],
                })
            }).then(response => response.json())
            .then(data => {
                if (data.status == "success"){
                  console.log("response is success")
                    navigate(`/verify?id=${data.id}`);
                }
            })
          const data = await response.json();
          setEmail('');
        } 
        catch (error) {
          console.error("submit.js l34"+error);
        } finally {
        //so can resubmit again
          setLoading(false);
        }
      };

    return ( 
        <div class="">
            <form onSubmit={handleSignup}>
            <div>
            <input value={email} onChange={changeName} className="email-capture-landing" placeholder="📬 a.abouelatta@gmail.com"></input>                  
            </div>
            <div>
            <button className="btn-free-access Color-background Background-color-red" type="submit" onClick={handleSignup} disabled={loading}> 🚀 Get Instant Access (For Free) </button> 
            </div>
          </form>
          </div>
    );
  }
  
  export default Submit;