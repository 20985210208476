import React, { useState, useEffect } from 'react'

function Welcome() {
  const [featureEnabled, setfeatureEnabled] = useState(false)
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/api/featureflag', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "flagName": "test_welcome_blue"
          })
        });
        const data = await response.json();
        setfeatureEnabled(data.featureEnabled);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="Page-div">
        <p>Hello World</p>
        {featureEnabled ? <h1>Feature Flag enabled</h1>:null}
    </div>
  );
}

export default Welcome;